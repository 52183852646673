import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import { PostListing, SEO, HTML, Pagination } from '../components'
import { Container } from '@caldera-digital/theme'
import { FancyTitle } from '../components/FancyTitle/FancyTitle'
import { graphql } from 'gatsby'
import { capitalize } from '../utils/utilityService'

class Listing extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges
    const {
      pageContext: { category, currentPageNum, pageCount },
      data: {
        pagesYaml: { cover, intro },
      },
    } = this.props
    return (
      <Layout heroImage={cover}>
        <Helmet title={`${capitalize(category)} | ${config.siteTitle}`} />
        <SEO />
        <Container>
          <HTML html={intro} />
          <FancyTitle>explore below</FancyTitle>
          <PostListing postEdges={postEdges} />

          <Pagination
            currentPageNum={currentPageNum}
            pageCount={pageCount}
            pathPrefix={category}
          />
        </Container>
      </Layout>
    )
  }
}

export default Listing

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!, $category: String!) {
    pagesYaml(id: { eq: $category }) {
      intro
      id
      cover
    }
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fileAbsolutePath: { glob: "!**/authors/*" }
        frontmatter: { category: { eq: $category } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date(formatString: "MM/DD/YYYY")
            category
            author {
              frontmatter {
                name
              }
            }
          }
        }
      }
    }
  }
`
